<template>
  <a-form
    ref="formRef"
    class="myform"
    :model="formState"
    :rules="rules"
    :wrapper-col="{ span: 14 }"
    :scroll-to-first-error="true"
  >
    <a-row class="form-row">
      <a-col :sm="24">
        <a-form-item
          label="Kode"
          label-align="left"
          :label-col="{ sm: { span: 4 } }"
          :wrapper-col="{ sm: { span: 24 - 4 } }"
          name="code"
        >
          <a-input
            placeholder="Kode"
            width="100%"
            v-model:value="formState.code"
            :disabled="!isNew"
            :type="codeType"
          />
        </a-form-item>
      </a-col>
    </a-row>
    <!-- kode referensi -->
    <a-row class="form-row">
      <a-col :sm="{ span: 24, offset: 4 }">
        <a-form class="myform-vertical" layout="vertical">
          <template v-for="(item, index) in vendorCodes.filter(i => !i.is_deleted)" :key="index">
            <a-input
              size="small"
              type="hidden"
              v-model:value="item.vendor_id"
              :disabled="formState.readOnly || item.id !== null"
            />
            <a-row class="form-row">
              <a-col :sm="4">
                <a-form-item
                  label="Kode Referensi"
                  label-align="left"
                  :name="['vendorCodes', 'code']"
                  has-feedback
                >
                  <a-input
                    size="small"
                    type="number"
                    v-model:value="item.code"
                    :disabled="formState.readOnly || item.id !== null"
                  />
                </a-form-item>
              </a-col>
              <a-col :sm="4">
                <a-form-item label="Nama" label-align="left">
                  <a-input
                    size="small"
                    v-model:value="item.name"
                    :disabled="formState.readOnly || item.id !== null"
                  />
                </a-form-item>
              </a-col>
              <a-col :sm="4">
                <a-form-item label="Tanggal Mulai" label-align="left">
                  <a-date-picker
                    size="small"
                    type="text"
                    v-model:value="item.begin_date"
                    :disabled="formState.readOnly || item.id !== null"
                  />
                </a-form-item>
              </a-col>
              <a-col :sm="4">
                <a-form-item label="Tanggal Selesai" label-align="left">
                  <a-date-picker
                    size="small"
                    type="text"
                    v-model:value="item.end_date"
                    :disabled="formState.readOnly || item.id !== null"
                  />
                </a-form-item>
              </a-col>
              <a-col :sm="4">
                <a-form-item label="Brand" label-align="left">
                  <filter-brand
                    size="small"
                    :mode="null"
                    type="text"
                    v-model:value="item.brand_id"
                    :disabled="formState.readOnly || item.id !== null"
                    style="width: 100%;"
                  ></filter-brand>
                </a-form-item>
              </a-col>

              <a-col :sm="4">
                <a-form-item label=" " label-align="left">
                  <a-button
                    @click="removeRow(item)"
                    size="small"
                    type="danger"
                    :disabled="formState.readOnly"
                    ><i class="fa fa-remove"></i
                  ></a-button>
                </a-form-item>
              </a-col>
            </a-row>
          </template>
        </a-form>
        <!-- add function -->
        <template v-if="!formState.readOnly">
          <a-form-item
            label-align="left"
            :wrapper-col="{ sm: { span: 24 - 4 - 8 } }"
            :colon="false"
          >
            <a-button type="primary" @click="addCode"
              ><i class="fa fa-plus"></i> Tambah Kode Referensi</a-button
            >
          </a-form-item>
        </template>
      </a-col>
    </a-row>

    <a-row class="form-row">
      <a-col :md="12" :sm="24">
        <a-form-item
          label="Nama Distributor"
          label-align="left"
          :label-col="{ sm: { span: 8 } }"
          :wrapper-col="{ sm: { span: 24 - 8 } }"
          required
          has-feedback
          name="name"
        >
          <a-input
            placeholder="Nama Distributor"
            v-model:value="formState.name"
            :disabled="formState.readOnly"
            required
          />
        </a-form-item>
      </a-col>
      <a-col :md="12" :sm="24">
        <a-form-item
          label="Inisial Distributor"
          label-align="left"
          :label-col="{ sm: { span: 8 } }"
          :wrapper-col="{ sm: { span: 24 - 8 } }"
          name="short_code"
        >
          <a-input
            placeholder="Inisial Distributor"
            v-model:value="formState.short_code"
            :disabled="formState.readOnly"
          />
        </a-form-item>
      </a-col>
    </a-row>

    <a-row class="form-row">
      <a-col :sm="24">
        <a-form-item
          label="Nama Pemilik"
          label-align="left"
          :label-col="{ sm: { span: 4 } }"
          :wrapper-col="{ sm: { span: 24 - 4 } }"
          required
          has-feedback
          name="owner"
        >
          <a-input
            placeholder="Nama Pemilik"
            v-model:value="formState.owner"
            :disabled="formState.readOnly"
          />
        </a-form-item>
      </a-col>
    </a-row>

    <a-row class="form-row">
      <a-col :md="12" :sm="24">
        <a-form-item
          label="NPWP"
          label-align="left"
          :label-col="{ sm: { span: 8 } }"
          :wrapper-col="{ sm: { span: 24 - 8 } }"
          required
          name="npwp"
        >
          <a-input
            placeholder="NPWP"
            v-model:value="formState.npwp"
            :disabled="formState.readOnly"
          />
        </a-form-item>
      </a-col>
      <a-col :md="12" :sm="24">
        <a-form-item
          label="Email"
          label-align="left"
          :label-col="{ sm: { span: 8 } }"
          :wrapper-col="{ sm: { span: 24 - 8 } }"
          required
          name="email"
        >
          <a-input
            placeholder="Contoh : example@mail.com"
            v-model:value="formState.email"
            :disabled="formState.readOnly"
          >
          </a-input>
        </a-form-item>
      </a-col>
    </a-row>

    <a-row class="form-row">
      <a-col :md="12" :sm="24">
        <a-form-item
          label="Nomor Telepon"
          label-align="left"
          :label-col="{ sm: { span: 8 } }"
          :wrapper-col="{ sm: { span: 24 - 8 } }"
          required
          name="phone"
        >
          <a-input
            addon-before="+62"
            onkeypress="return event.charCode >= 48 && event.charCode <= 57"
            pattern="^[1-9][0-9]*$"
            oninput="if(!this.value.match('^[1-9][0-9]*$'))this.value='';"
            placeholder="Ex : 81xxxxxxxxx"
            v-model:value="formState.phone"
            :disabled="formState.readOnly"
            _type="number"
            min="0"
            maxlength="13"
          />
        </a-form-item>
      </a-col>
      <a-col :md="12" :sm="24">
        <a-form-item
          label="Nomor Telepon 2"
          label-align="left"
          :label-col="{ sm: { span: 8 } }"
          :wrapper-col="{ sm: { span: 24 - 8 } }"
        >
          <a-input
            addon-before="+62"
            onkeypress="return event.charCode >= 48 && event.charCode <= 57"
            pattern="^[1-9][0-9]*$"
            oninput="if(!this.value.match('^[1-9][0-9]*$'))this.value='';"
            placeholder="Ex : 81xxxxxxxxx"
            v-model:value="formState.phone2"
            :disabled="formState.readOnly"
            _type="number"
            min="0"
            maxlength="13"
          />
        </a-form-item>
      </a-col>
    </a-row>

    <a-row class="form-row">
      <a-col :md="12" :sm="24">
        <a-form-item
          label="Target Volume"
          label-align="left"
          :label-col="{ sm: { span: 8 } }"
          :wrapper-col="{ sm: { span: 24 - 8 } }"
        >
          <a-input
            addon-after="TON"
            width="100%"
            type="number"
            :min="0"
            v-model:value="formState.target_volume"
            placeholder="Target Distributor"
            :disabled="formState.readOnly"
          />
        </a-form-item>
      </a-col>
    </a-row>

    <a-row class="form-row">
      <a-col :md="12" :sm="24">
        <a-form-item
          label="Foto KTP"
          label-align="left"
          :label-col="{ sm: { span: 8 } }"
          :wrapper-col="{ sm: { span: 24 - 8 } }"
        >
          <template v-if="!formState.readOnly">
            <a-input
              required
              v-if="!foto_ktp || foto_ktp === 'null'"
              type="file"
              accept="image/png, image/gif, image/jpeg"
              @change="e => onFileChange(e, 'ktp')"
            ></a-input>
            <a-button
              v-else
              @click="
                () => {
                  foto_ktp = null
                }
              "
              >Hapus ktp</a-button
            >
          </template>
          <img
            v-if="foto_ktp !== 'null'"
            style="display: block; max-width:150px;
            width: auto;
            height: auto;"
            :src="typeof foto_ktp === 'string' ? foto_ktp : foto_ktp_image"
          />
        </a-form-item>
      </a-col>
      <a-col :md="12" :sm="24">
        <a-form-item
          label="Foto Distributor"
          label-align="left"
          :label-col="{ sm: { span: 8 } }"
          :wrapper-col="{ sm: { span: 24 - 8 } }"
        >
          <template v-if="!formState.readOnly">
            <a-input
              required
              v-if="!foto || foto === 'null'"
              type="file"
              accept="image/png, image/gif, image/jpeg"
              @change="e => onFileChange(e, 'foto')"
            ></a-input>
            <a-button
              v-else
              @click="
                () => {
                  foto = null
                }
              "
              >Hapus Foto</a-button
            >
          </template>
          <img
            v-if="foto !== 'null'"
            style="display: block; max-width:150px;
            width: auto;
            height: auto;"
            :src="typeof foto === 'string' ? foto : foto_image"
          />
        </a-form-item>
      </a-col>
    </a-row>

    <a-row class="form-row">
      <a-col :md="12" :sm="24">
        <a-form-item
          label="Logo Distributor"
          label-align="left"
          :label-col="{ sm: { span: 8 } }"
          :wrapper-col="{ sm: { span: 24 - 8 } }"
        >
          <template v-if="!formState.readOnly">
            <a-input
              required
              v-if="!logo || logo === 'null'"
              type="file"
              accept="image/png, image/gif, image/jpeg"
              @change="e => onFileChange(e, 'logo')"
            ></a-input>
            <a-button
              v-else
              @click="
                () => {
                  logo = null
                }
              "
              >Hapus Logo</a-button
            >
          </template>
          <img
            v-if="logo !== 'null'"
            style="display: block; max-width:150px;
            width: auto;
            height: auto;"
            :src="typeof logo === 'string' ? logo : logo_image"
          />
        </a-form-item>
      </a-col>
      <a-col :md="12" :sm="24">
        <a-form-item
          label="Dokumen Distributor"
          label-align="left"
          :label-col="{ sm: { span: 8 } }"
          :wrapper-col="{ sm: { span: 24 - 8 } }"
        >
          <template v-if="!formState.readOnly">
            <a-input
              required
              v-if="!document || document === 'null'"
              type="file"
              @change="e => onFileChange(e, 'document')"
            ></a-input>
            <a-button
              v-else
              @click="
                () => {
                  document = null
                }
              "
              >Hapus Dokumen</a-button
            ><br />
          </template>
          <a
            v-if="
              document !== null &&
                document !== undefined &&
                typeof document === 'string' &&
                document !== 'null'
            "
            :href="!document ? 'javascript.void(0)' : document"
            target="_blank"
            >Lihat Dokumen</a
          >
        </a-form-item>
      </a-col>
    </a-row>

    <a-row class="form-row">
      <a-col :sm="24">
        <a-form-item
          label="Alamat"
          label-align="left"
          :label-col="{ sm: { span: 4 } }"
          :wrapper-col="{ sm: { span: 24 - 4 } }"
          required
          name="address"
        >
          <a-input
            placeholder="Alamat"
            v-model:value="formState.address"
            :disabled="formState.readOnly"
          />
        </a-form-item>
      </a-col>
    </a-row>

    <a-row class="form-row">
      <a-col :sm="24">
        <a-form-item
          label="Pilih Kabupaten/Kota"
          label-align="left"
          :label-col="{ sm: { span: 4 } }"
          :wrapper-col="{ sm: { span: 24 - 4 } }"
          required
          name="wilayah_id"
        >
          <filter-wilayah-semua
            v-model:value="formState.wilayah_id"
            label="fullname"
            :disabled="formState.readOnly"
            :join-options="
              formState.wilayah_id
                ? [{ id: formState.wilayah_id, fullname: formState.wilayah }]
                : []
            "
          ></filter-wilayah-semua>
        </a-form-item>
      </a-col>
    </a-row>

    <a-row class="form-row">
      <a-col :sm="24">
        <a-form-item
          label="Pilih Distrik Sidigi"
          label-align="left"
          :label-col="{ sm: { span: 4 } }"
          :wrapper-col="{ sm: { span: 24 - 4 } }"
          name="district_sidigi_code"
        >
          <filter-distrik-sidigi
            show-search
            allow-clear
            v-model:value="formState.district_sidigi_code"
            v-model:wilayah="formState.wilayah_id"
            label="fullname"
            :disabled="formState.readOnly"
            :mode="null"
          ></filter-distrik-sidigi>
        </a-form-item>
      </a-col>
    </a-row>

    <a-row class="form-row">
      <a-col :sm="24">
        <a-form-item
          label="Pilih Salesman"
          label-align="left"
          :label-col="{ sm: { span: 4 } }"
          :wrapper-col="{ sm: { span: 24 - 4 } }"
          name="salesman"
        >
          <FilterSalesman
            show-search
            allow-clear
            v-model:value="formState.salesman"
            v-model:items="formState.salesmans"
            :vendor_id="formState.id"
            label="fullname"
            :disabled="formState.readOnly"
            mode="multiple"/>
        </a-form-item>
      </a-col>
    </a-row>

    <a-row class="form-row">
      <a-col :sm="24">
        <a-form-item
          label="Keterangan"
          :label-col="{ sm: { span: 4 } }"
          :wrapper-col="{ sm: { span: 24 - 4 } }"
          label-align="left"
        >
          <a-input
            placeholder="keterangan"
            v-model:value="formState.note"
            :disabled="formState.readOnly"
            :auto-size="{ minRows: 5 }"
          /> </a-form-item
      ></a-col>
    </a-row>
  </a-form>
</template>

<script>
import { ref, reactive, inject, computed, nextTick, toRef, onBeforeMount, toRefs } from 'vue'
import apiClient from '@/services/axios'
import FilterWilayahSemua from '@/components/filter/FilterWilayahSemua'
import FilterBrand from '@/components/filter/FilterBrand'
import FilterDistrikSidigi from '@/components/filter/FilterDistrikSidigi'
import FilterSalesman from '@/components/filter/FilterSalesman'
import { message } from 'ant-design-vue'
import rules from './rules'

export default {
  components: {
    FilterWilayahSemua,
    FilterBrand,
    FilterDistrikSidigi,
    FilterSalesman,
  },
  setup(props, { emit }) {
    const defWrapCol = reactive({ md: { span: 16, offset: 4 }, sm: { span: 24 } })
    const formState = inject('formState')
    const foto_ktp = toRef(formState.value, 'foto_ktp')
    const foto = toRef(formState.value, 'foto')
    const logo = toRef(formState.value, 'logo')
    const fotos = reactive({
      foto_ktp_image: null,
      foto_image: undefined,
      logo_image: undefined,
    })
    const document = toRef(formState.value, 'document')
    const readOnly = false
    const visible = ref(false)
    const dataWilayah = ref([])
    const dataProvince = ref([])
    const codeType = ref('number')

    const formRef = ref('')

    const vendorCode = ref({
      id: null,
      brand_id: null,
      vendor_id: null,
      code: null,
      name: '',
      begin_date: null,
      end_date: null,
    })

    const vendorCodes = toRef(formState.value, 'vendorCodes')

    onBeforeMount(() => {
      if (formState.value.vendorCodes === undefined || formState.value.vendorCodes === null) {
        formState.value.vendorCodes = []
      }
    })

    const generateEmail = () => console.log('genraete')

    const isNew = computed(() => formState.value.state === 'new')

    const urlUpload = apiClient.defaults.baseURL + '/api/upload'
    // filelist upload, foto distributor, foto, foto, document
    const fKtp = ref([])
    const ktp = ref(true)
    const ifFoto = ref(true)
    const ifLogo = ref(true)
    const ifDokumen = ref(true)

    const action = file => {
      var FormData = require('form-data')
      var data = new FormData()
      return new Promise((resolve, reject) => {
        return resolve({
          data: { satu: 'https:\/\/storage.aksestoko.id\/f\/20f2a783-8d2f-4896-988f-86cfb0d8c93a.png' },
        })
      })
    }

    const onFileChange = async (e, type) => {
      var files = e.target.files || e.dataTransfer.files
      console.log(files.length, type)
      if (!files.length) return
      var filesize = (files[0].size / 1024 / 1024).toFixed(4) // MB
      if (filesize > 2.0) {
        message.warning('Ukuran harus di bawah 2MB!', 5.0)
        if ('ktp' === type) {
          ktp.value = false
        } else if ('foto' === type) {
          ifFoto.value = false
        } else if ('logo' === type) {
          ifLogo.value = false
        } else if ('document' === type) {
          ifDokumen.value = false
        }
        await nextTick()
        if ('ktp' === type) {
          ktp.value = true
        } else if ('foto' === type) {
          ifFoto.value = true
        } else if ('logo' === type) {
          ifLogo.value = true
        } else if ('document' === type) {
          ifDokumen.value = true
        }
        return
      }

      if ('ktp' === type) {
        // action(files[0]).then(({data}) => {foto_ktp.value = data.satu});
        // imageToBase64(files[0], foto_ktp)
        foto_ktp.value = files[0]
        imageToBase64(foto_ktp.value, toRefs(fotos).foto_ktp_image)
      } else if ('foto' === type) {
        foto.value = files[0]
        imageToBase64(foto.value, toRefs(fotos).foto_image)
      } else if ('logo' === type) {
        logo.value = files[0]
        imageToBase64(logo.value, toRefs(fotos).logo_image)
      } else if ('document' === type) {
        document.value = files[0]
        // action(files[0]).then(({data}) => {document.value = data.satu});
        // imageToBase64(files[0], document)
      }
    }

    const imageToBase64 = (file, to) => {
      var reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        to.value = reader.result
      }
      reader.onerror = function(error) {
        console.log('Error: ', error)
      }
    }

    /// vendor code
    const addCode = () => {
      if (vendorCodes.value.filter(v => v.code === null).length > 0) {
        message.warning('Pastikan kode referensi terisi!')
        return
      }
      isAddCode.value = false
      const m = Object.assign({}, vendorCode.value)
      // m.id = 1
      vendorCodes.value.push(m)
      // resetCode()
    }

    const removeRow = item => {
      const index = vendorCodes.value.indexOf(item)
      if (item.id === null) vendorCodes.value.splice(index, 1)
      else {
        item.is_deleted = true
      }
    }

    const isAddCode = ref(true)
    const saveCode = () => {
      isAddCode.value = true
      resetCode()
    }
    const batalCode = () => {
      isAddCode.value = true
    }
    const resetCode = () => {
      vendorCode.value = Object.assign(
        {},
        {
          id: null,
          brand_id: null,
          vendor_id: null,
          code: null,
          begin_date: null,
          end_date: null,
        },
      )
    }

    // const validate = () => {
    // return formRef.value.validate()
    // }

    return {
      defWrapCol,
      formState,
      generateEmail,
      dataProvince,
      dataWilayah,
      isNew,
      visible,
      addCode,
      vendorCode,
      vendorCodes,
      removeRow,
      fKtp,
      urlUpload,
      action,
      onFileChange,

      ktp,
      ifFoto,
      ifLogo,
      ifDokumen,
      foto_ktp,
      foto,
      logo,
      document,
      imageToBase64,
      isAddCode,
      saveCode,
      batalCode,
      formRef,
      rules,
      // validate,
      ...toRefs(fotos),
      codeType,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/form.scss';
</style>
